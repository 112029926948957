<template>
  <div class="m-2">
    <arq-previews-page />
    <h1>Reclamaciónes PQR</h1>
    <div class="col col-md-6 col-sm-2 mx-auto  my-4">
      <div
        v-if="verOpciones"
        class="card p-2"
      >
        <h4 class="mb-1 text-center">
          ¿La PQR que desea registrar, pertenece a su Servicio?
        </h4>
        <b-row>
          <b-col md="6">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                variant="primary"
                @click="si()"
              >
                Si
              </b-button>
            </div>
          </b-col>

          <b-col md="6">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                block
                variant="outline-primary"
                @click="no()"
              >
                No
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <form
      v-if="typeForm1"
      class="col col-md-6 col-sm-2 mx-auto  my-4"
    >
      <div class="card p-2">
        <div
          v-for="(f, index) in form"
          :key="index"
        >
          <label :for="f.key">
            {{ f.label }}
          </label>
          <div v-if="types.includes(f.type)">
            <div v-if="f.key.includes('identification')">
              <b-form-input
                :id="f.key"
                v-model="data[f.key]"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
                @input="getContract(data[f.key])"
              />
            </div>
            <div v-if="f.key.includes('phone')">
              <b-form-input
                :id="f.key"
                v-model="phone"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
              />
            </div>
            <div v-if="f.key.includes('email')">
              <b-form-input
                :id="f.key"
                v-model="email"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
              />
            </div>
            <b-form-input
              v-if="
                !f.key.includes('email') &&
                  !f.key.includes('phone') &&
                  !f.key.includes('identification')
              "
              :id="f.key"
              v-model="data[f.key]"
              :name="f.key"
              :type="f.type"
              :required="f.required"
              :disabled="f.disabled"
            />
          </div>
          <div v-if="f.type === 'select-contract'">
            <b-form-select
              :id="f.key"
              v-model.number="data[f.key]"
              value-field="idProducto"
              text-field="descservicio"
              :options="contratos"
              @input="getOptionServices(data[f.key])"
            />
          </div>
          <b-form-file
            v-if="f.type === 'file'"
            :id="f.key"
            :state="Boolean(data[f.key])"
            placeholder="Adjuntar documento..."
            drop-placeholder="Suelta el documento aqui..."
            @input="uploadImage()"
          />
          <div v-if="f.type === 'textarea'">
            <b-form-textarea
              :id="f.key"
              v-model="data[f.key]"
              rows="8"
            />
          </div>
        </div>
        <div class="col-12">
          <b-button
            :disabled="disabledButton"
            variant="primary"
            class="mt-3 d-flex text-center w-100 mr-auto ml-auto justify-content-center"
            @click="confirm(4000)"
          >
            <div v-if="loading">
              <b-spinner
                small
                class="mr-1"
                label="Small Spinner"
              />
            </div>
            Confirmar
          </b-button>
        </div>
      </div>
    </form>
    <form
      v-if="typeForm2"
      class="w-50 lg:w-25 mx-auto my-4"
    >
      <div class="card p-2">
        <div
          v-for="(f, index) in form2"
          :key="index"
        >
          <label :for="f.key">
            {{ f.label }}
          </label>
          <div v-if="types.includes(f.type)">
            <div v-if="f.key.includes('identification')">
              <b-form-input
                :id="f.key"
                v-model="data[f.key]"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
                @input="getContract(data[f.key])"
              />
            </div>
            <div v-if="f.key.includes('phone')">
              <b-form-input
                :id="f.key"
                v-model="phone"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
              />
            </div>
            <div v-if="f.key.includes('contrato')">
              <b-form-input
                :id="f.key"
                v-model="contrato"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
              />
            </div>
            <div v-if="f.key.includes('email')">
              <b-form-input
                :id="f.key"
                v-model="email"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
              />
            </div>
            <b-form-input
              v-if="
                !f.key.includes('email') &&
                  !f.key.includes('phone') &&
                  !f.key.includes('identification') &&
                  !f.key.includes('contrato')
              "
              :id="f.key"
              v-model="data[f.key]"
              :name="f.key"
              :type="f.type"
              :required="f.required"
              :disabled="f.disabled"
            />
          </div>
          <div v-if="f.type === 'select-contract'">
            <b-form-select
              :id="f.key"
              v-model.number="data[f.key]"
              value-field="idProducto"
              text-field="descservicio"
              :options="contratos"
              @input="getOptionServices(data[f.key])"
            />
          </div>
          <b-form-file
            v-if="f.type === 'file'"
            :id="f.key"
            :state="Boolean(data[f.key])"
            placeholder="Adjuntar documento..."
            drop-placeholder="Suelta el documento aqui..."
            @input="uploadImage()"
          />
          <div v-if="f.type === 'textarea'">
            <b-form-textarea
              :id="f.key"
              v-model="data[f.key]"
              rows="8"
            />
          </div>
        </div>
        <div class="col-12">
          <b-button
            :disabled="disabledButton"
            variant="primary"
            class="mt-3 d-flex text-center w-100 mr-auto ml-auto justify-content-center"
            @click="confirm(4000)"
          >
            <div v-if="loading">
              <b-spinner
                small
                class="mr-1"
                label="Small Spinner"
              />
            </div>
            Confirmar
          </b-button>
        </div>
      </div>
    </form>
    <form
      v-if="typeForm3"
      class="w-50 lg:w-25 mx-auto my-4"
    >
      <div class="card p-2">
        <div
          v-for="(f, index) in form3"
          :key="index"
        >
          <label :for="f.key">
            {{ f.label }}
          </label>
          <div v-if="types.includes(f.type)">
            <div v-if="f.key.includes('identification')">
              <b-form-input
                :id="f.key"
                v-model="data[f.key]"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
                @input="getContract(data[f.key])"
              />
            </div>
            <div v-if="f.key.includes('phone')">
              <b-form-input
                :id="f.key"
                v-model="phone"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
              />
            </div>
            <div v-if="f.key.includes('contrato')">
              <b-form-input
                :id="f.key"
                v-model="data[f.key]"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
                @input="getOptionServices(data[f.key])"
              />
            </div>
            <div v-if="f.key.includes('email')">
              <b-form-input
                :id="f.key"
                v-model="email"
                :name="f.key"
                :type="f.type"
                :required="f.required"
                :disabled="f.disabled"
              />
            </div>
            <b-form-input
              v-if="
                !f.key.includes('email') &&
                  !f.key.includes('phone') &&
                  !f.key.includes('identification') &&
                  !f.key.includes('contrato')
              "
              :id="f.key"
              v-model="data[f.key]"
              :name="f.key"
              :type="f.type"
              :required="f.required"
              :disabled="f.disabled"
            />
          </div>
          <div v-if="f.type === 'select-contract'">
            <b-form-select
              :id="f.key"
              v-model.number="data[f.key]"
              value-field="idProducto"
              text-field="descservicio"
              :options="contratos"
              @input="getOptionServices(data[f.key])"
            />
          </div>
          <b-form-file
            v-if="f.type === 'file'"
            :id="f.key"
            :state="Boolean(data[f.key])"
            placeholder="Adjuntar documento..."
            drop-placeholder="Suelta el documento aqui..."
            @input="uploadImage()"
          />
          <div v-if="f.type === 'textarea'">
            <b-form-textarea
              :id="f.key"
              v-model="data[f.key]"
              rows="8"
            />
          </div>
        </div>
        <div class="col-12">
          <b-button
            :disabled="disabledButton"
            variant="primary"
            class="mt-3 d-flex text-center w-100 mr-auto ml-auto justify-content-center"
            @click="confirm(4000)"
          >
            <div v-if="loading">
              <b-spinner
                small
                class="mr-1"
                label="Small Spinner"
              />
            </div>
            Confirmar
          </b-button>
        </div>
      </div>
    </form>
    <b-modal
      id="modal-confirm-process"
      title="Tramite"
      ok-only
    >
      <div v-if="!response.message">
        <p class="my-4">
          <b-spinner
            variant="success"
            label="Spinning"
          />
          Enviando solicitud!
        </p>
      </div>
      <div v-else>
        <b-alert
          :variant="response.variant"
          show
        >
          {{ response.message }}
        </b-alert>
      </div>
    </b-modal>
    <b-modal
      id="modal-actualizar-datos-personales"
      title="Actualizar datos personales"
      hide-footer
    >
      <!-- ok-title="Confirmar"
      cancel-title="Cancelar"
      @ok="handleOk" -->
      <div v-if="!responseUpdatePersonal.message">
        <b-alert
          variant="danger"
          show
        >
          Necesita actualizar los datos personales
        </b-alert>
        <form
          ref="formActualizarDatosPersonales"
          @submit.stop.prevent="handleSubmit"
        >
          <!-- <b-form-group
            label="Identifiacion"
            label-for="identifiacion-input"
            invalid-feedback="Identificacion es requerida"
            :state="identificacionState"
          >
            <b-form-input
              id="identifiacion-input"
              v-model="dataPersonal.identificacion"
              required
              type="number"
              :state="identificacionState"
            />
          </b-form-group> -->
          <b-form-group
            label="Telefono"
            label-for="phone-input"
            invalid-feedback="Telefono es requerido"
            :state="phoneState"
          >
            <b-form-input
              id="phone-input"
              v-model="dataPersonal.celularespo"
              max-length="10"
              min-length="10"
              required
              type="number"
              :state="phoneState"
            />
          </b-form-group>
          <b-form-group
            label="Correo"
            label-for="email-input"
            invalid-feedback="Email es requerido"
            :state="emailState"
          >
            <b-form-input
              id="email-input"
              v-model="dataPersonal.emailresposerv"
              required
              type="email"
              :state="emailState"
            /></b-form-group>
        </form>
        <button
          class="bg-primary text-white m-auto mr-auto btn"
          style="position: relative; margin-left: 12em !important;"
          @click.prevent="handleOk"
        >
          Confirmar
        </button>
      </div>
      <div v-else>
        <b-alert
          :variant="responseUpdatePersonal.variant"
          show
        >
          a {{ responseUpdatePersonal.message }}
        </b-alert>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BSpinner } from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  components: {
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      phone: '',
      email: '',
      contrato: '-1',
      response: {},
      loading: false,
      typeForm1: false,
      typeForm2: false,
      typeForm3: false,
      verOpciones: true,
      form: [
        {
          label: 'Contrato',
          key: 'contrato',
          type: 'select-contract',
          required: true,
        },
        {
          label: 'Celular',
          key: 'phone',
          type: 'number',
          disabled: true,
          required: true,
        },
        {
          label: 'Correo',
          key: 'email',
          type: 'text',
          disabled: true,
          required: true,
        },
        {
          label: 'Observacion',
          key: 'observacion',
          type: 'textarea',
          required: true,
        },
      ],
      form2: [
        {
          label: 'Contrato',
          key: 'contrato',
          type: 'number',
          required: true,
          disabled: true,
        },
        {
          label: 'Celular',
          key: 'phone',
          value: '-1',
          type: 'number',
          disabled: false,
          required: true,
        },
        {
          label: 'Correo',
          key: 'email',
          type: 'text',
          disabled: false,
          required: true,
        },
        {
          label: 'Observacion',
          key: 'observacion',
          type: 'textarea',
          required: true,
        },
      ],
      form3: [
        {
          label: 'Contrato',
          key: 'contrato',
          type: 'number',
          required: true,
          disabled: false,
        },
        {
          label: 'Celular',
          key: 'phone',
          value: '-1',
          type: 'number',
          disabled: true,
          required: true,
        },
        {
          label: 'Correo',
          key: 'email',
          type: 'text',
          disabled: true,
          required: true,
        },
        {
          label: 'Observacion',
          key: 'observacion',
          type: 'textarea',
          required: true,
        },
      ],
      data: {},
      types: [
        'text',
        'number',
        'email',
        'password',
        'search',
        'url',
        'tel',
        'date',
        'time',
        'range',
        'color',
      ],
      optionsServices: [],
      idService: 0,
      imageSrc: '',
      fileType: '',
      fileName: '',
      disabledButton: false,
      process: {},
      responseUpdatePersonal: {},
      phoneState: false,
      emailState: false,
      dataPersonal: {},
    }
  },
  computed: {
    ...mapState('client', ['contratos']),
  },
  mounted() {
    this.validateLogin()
    this.typeForm2 = false
  },
  methods: {
    mounted() {
      this.data.identificacion = this.$store.state.client.client.identificacion
    },
    getOptionServices(contrato) {
      this.phone = ''
      this.email = ''
      axios
        .get(`/app/services/fromContract?idContract=${contrato}`)
        .then(res => {
          if (res.data.code === 0) {
            this.optionsServices = res.data.list
            if (res.data.object.idProductoDefault === 0) {
              this.idService = -2
            } else {
              this.idService = res.data.object.idProductoDefault
            }
            this.phone = res.data.object.celularespo
            this.email = res.data.object.emailresposerv
          } else {
            this.optionsServices = []
          }
        })
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      let validate = false
      // if (!this.dataPersonal.identificacion) {
      //   validate = this.checkFormValidity()
      //   if (!validate) {
      //     // console.log('blos')
      //     return false
      //   }
      //   return false
      // }
      if (!this.dataPersonal.celularespo && !this.dataPersonal.emailresposer) {
        validate = this.checkFormValidity()
        if (!validate) {
          // this.identificacionState = true
          // console.log('blos')
          return false
        }
      }

      // Prevent modal from closing
      validate = this.checkFormValidity()
      this.phoneState = true
      this.emailState = true
      // this.identificacionState = true
      // console.log('bla')
      this.dataPersonal.identificacion = this.data.identification
      axios
        .post('/app/person/update', this.dataPersonal)
        .then(res => {
          if (res.data.code === 0) {
            this.responseUpdatePersonal.message = 'Sus datos han sido actualizados exitosamente.'
            this.responseUpdatePersonal.variant = 'success'
            this.data = {}
          } else {
            this.responseUpdatePersonal.message = 'Error al intentar actualizar los datos.'
            this.responseUpdatePersonal.variant = 'danger'
          }
        })
        .finally(() => {
          this.dataPersonal = {}
        })
      this.$nextTick(() => {
        this.$bvModal.hide('modal-actualizar-datos-personales')
      })
      return true
      // Trigger submit handler
    },
    checkFormValidity() {
      const valid = this.$refs.formActualizarDatosPersonales.checkValidity()
      return valid
    },
    openActualizacionDatos() {
      this.$bvModal.show('modal-actualizar-datos-personales')
    },
    confirm() {
      this.response = {}
      // if (this.process.validaPhoneAndEmail) {
      if (
        this.phone === ''
        || this.phone === null
        || !this.phone
        || !this.email
        || this.email === ''
        || this.email === null
      ) {
        this.openActualizacionDatos()
        return
      }
      // }
      this.loading = true
      this.disabledButton = true
      this.data.idService = this.idService
      this.data.phone = this.phone
      this.data.email = this.email
      const separador = '-'
      const form = {
        email: this.email,
        idService: this.idService,
        observacion: `Correo: ${this.email}${separador}Celular: ${this.phone}${separador}Observacion: ${this.data.observacion}`,
        phone: this.phone,
      }
      this.$bvModal.show('modal-confirm-process')
      this.sendGetProcedure(form)
        .then(res => {
          if (res.data.code === 0) {
            this.response = res.data
            this.response.message = res.data.msg || 'Su tramite ha sido enviado'
            this.response.variant = 'success'
          } else {
            this.response = res.data
            this.response.message = res.data.msg || 'Error al procesar su tramite'
            this.response.variant = 'danger'
          }
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: 'Mensaje',
          //     icon: 'AlertOctagonIcon',
          //     text: res.data.msg,
          //     variant: 'warning',
          //   },
          // }, {
          //   time,
          // })
          this.data = {}
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
          this.disabledButton = false
        })
    },

    async sendGetProcedure(formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/app/procedure/reclamacionesPQR', formData)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    openLogin() {
      this.$bvModal.show('modal-login')
    },
    no() {
      this.getOptionServices(-1)
      this.typeForm2 = true
      this.verOpciones = false
    },
    si() {
      this.typeForm3 = true
      this.verOpciones = false
    },
    validateLogin() {
      if (localStorage.getItem('access_token_portal')) {
        this.typeForm1 = true
        this.verOpciones = false
      } else {
        this.typeForm1 = false
        this.verOpciones = true
      }
    },
  },
}
</script>
